import axios from '@tw/shared-ui-components/lib/utils/axios_interceptor';

const apiUrl = process.env.PDM_BFF_API || "http://localhost:3002";

export const SET_ANNOUNCEMENT = "SET_ANNOUNCEMENT";

export const setAnnouncement = (announcement) => ({
  type: SET_ANNOUNCEMENT,
  payload: announcement,
});

export const fetchAnnouncement = (languageId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${apiUrl}/announcement?languageId=${languageId}`
    );
    dispatch(setAnnouncement(res.data));
  } catch (err) {
    console.log('announcement-reducer.js', err);
  }
};

const intialState = null;

const announcementReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case SET_ANNOUNCEMENT:
      return payload;
    default:
      return state;
  }
};

export default announcementReducer;
